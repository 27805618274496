<template>
    <v-card outlined>
        <v-card-text>
            <v-row wrap ro align="center">
                <v-col cols="12">
                    <form autocomplete="nope" @submit.prevent="postData">
                        <v-container grid-list-md fluid>
                            <v-row dense align="center">
                                <v-col cols="12" sm="8">
                                    <v-autocomplete dense v-model="servicio.id" v-validate="'required'" label="Producto" 
                                        :items="_listaProductosOut" data-vv-as="marca"
                                        :error-messages="errors.collect('servicio.id')" data-vv-name="servicio.id" required
                                        item-text="rename" item-value="id" >
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <v-text-field v-validate="'required'" required dense v-model="servicio.quantity" label="Unidades" @keypress="isNumber" />
                                </v-col>
                                <v-col cols="12" sm="2" class="text-center"><v-btn block color="secondaryTheme" dark
                                        type="submit">agregar</v-btn>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-container>
                    </form>
                </v-col>
                <v-col cols="12" class="grey lighten-5">
                    <v-data-table multi-sort :headers="headers" :items="inventory" class="elevation-1" :items-per-page="20"
                        item-key="timestamp">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="green"
                                        @click="upItem(item)">mdi-swap-vertical</v-icon>
                                </template>
                                <span>SUBIR</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" v-on="on" small
                                        @click="deleteItem(item)">mdi-delete</v-icon>
                                </template>
                                <span>ELIMINAR</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
export default {
    filters: {},
    props: ['lista', 'service',],
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {

            isLoading: false,
            model: null,
            servicio: DefaultForm.formSelectServicio(),
            listaServicios: [],
            search: null,
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Descripción', value: 'name' },
                { text: 'Codigo', value: 'internal_id' },
                { text: 'Unidades', value: 'quantity' },
            ]
        }
    },

    computed: {
        ...mapState('utilities', ['tipoDocumento', 'tipoComprobante', 'tipoBancoDestino']),
        ...mapState('inventario', ['dataproductoWarehouse', 'inventory']),


        _listaProductosOut() {
            let listaProductos = []
            if (this.dataproductoWarehouse) {
                this.dataproductoWarehouse.map((v) => {
                    listaProductos.push({
                        ...v,
                        rename: `${v.description} | ${v.internal_id}`
                    })
                })
            }

            return listaProductos
        }
    },
    methods: {

  

        deleteItem(item) {
            const lista = Object.assign([], this.inventory)
            this.$store.commit('inventario/UPDATE_LISTA_INVENTORY', lista.filter((v) => v.timestamp != item.timestamp))
            //this.listaServicios = lista
        },
        upItem(newItem) {
            const position = this.inventory.findIndex((x) => x.timestamp == newItem.timestamp)
            //console.log('position ', position)
            const lista = Object.assign([], this.inventory)
            if (position >= 1) {
                const oldItem = { ...lista[position - 1], timestamp: Date.now() }
                ////console.log('lista ', lista)
                lista.splice(position, 1, oldItem)
                ////console.log('lista 1', lista)
                lista.splice(position - 1, 1, newItem)
                ////console.log('lista ', lista)
                //colors.splice(2, 0, 'purple');

                this.$store.commit('inventario/UPDATE_LISTA_INVENTORY', lista)
            }
        },
        postData() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    const _servicio = this._listaProductosOut.find(x=>x.id == this.servicio.id)
                    console.log('this.servicio', this.servicio, _servicio)
                    const lista = Object.assign([], this.inventory)
                    //console.log('service')
                    lista.push({
                        id: this.servicio.id,
                        quantity: parseFloat(this.servicio.quantity),
                        name: _servicio.description,
                        internal_id: _servicio.internal_id,
                        timestamp: Date.now()

                    })
                    //console.log('lista', lista)
                    this.$store.commit('inventario/UPDATE_LISTA_INVENTORY', lista)
                    this.servicio = DefaultForm.formSelectServicio()
                }
            })
        }
    }
}
</script>
<style></style>
